import React, { useReducer, useEffect } from 'react';
import './App.css';

function App() {

  return (
    <div className="App">
      <h1>Murder Pro</h1>
      <audio controls={true} preload="metadata">
        <source src="/stream" type="audio/mpeg" />
      </audio>
    </div>
  );
}

export default App;
